import { render, staticRenderFns } from "./DialogAboutMuseumContent.vue?vue&type=template&id=00d80e5c&scoped=true&"
var script = {}
import style0 from "./DialogAboutMuseumContent.vue?vue&type=style&index=0&id=00d80e5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d80e5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
