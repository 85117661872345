<template>
  <div class="menu-container-center">
    <div class="menu-container-cover"></div>
    <div class="menu-container">
      <div id="menu-item-light" @click="openAboutMuseum">
        <div class="light-container">
          <div class="light">
            <img
              src="@/assets/images/menu/menu-item-light@2x.png"
              width="100%"
              height="auto"
              alt=""
            />
          </div>
          <div class="light-dot"></div>
        </div>
      </div>
      <div
        v-for="(item, i) in menuItems"
        :key="`menu-item-${i}`"
        :id="`menu-item-${i}`"
        :class="{
          'menu-item': true,
          'pointer-events-none': !clickable,
          disabled: !visitedAll && i == 3,
        }"
        :ref="`menuItem${i}`"
        @mouseenter="menuOver(i)"
        @mouseleave="menuOut(i)"
        @click="clickOnItem(i)"
      >
        <div class="main">
          <div v-if="i == 3" class="menu-item-tooltips">
            游歷三個展廳後<br />可解鎖圖錄
          </div>
          <inline-svg
            class="menu-item-svg"
            :src="require(`../assets/images/menu/menu-item-${i}.svg`)"
            @loaded="svgLoaded($event, i)"
          />
        </div>
        <div class="shadow">
          <img
            src="@/assets/images/menu/shadow.png"
            width="100% "
            height="auto"
            alt=""
          />
        </div>
      </div>
    </div>

    <template>
      <v-dialog
        v-model="dialog.museum"
        dark
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-btn
            class="btn-close-dialog"
            icon
            dark
            @click="dialog.museum = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar flat dark color="black">
            
          </v-toolbar> -->
          <v-card-text>
            <DialogAboutMuseumContent />
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { gsap, Sine, Power2, Power4 } from "gsap";
import DialogAboutMuseumContent from "@/components/DialogAboutMuseumContent.vue";

export default {
  data() {
    return {
      clickable: true,
      dialog: {
        museum: false,
      },
      tl: {
        main: null,
      },
      tweens: {
        menuTweenIn: null,
        menuTweenOut: null,
      },
      menuItems: [
        {
          name: "遊歷時間 exploratorium",
          to: "/exploratorium",
          loaded: false,
          complete: false,
          tl: {
            tl: null,
            tlMouse: null,
          },
          elem: {
            item: null,
            text: null,
            lights: null,
            body: null,
            shadow: null,
          },
        },
        {
          name: "聽繪音聲 laboratorium",
          to: "/laboratorium",
          loaded: false,
          complete: false,
          tl: {
            tl: null,
            tlMouse: null,
          },
          elem: {
            item: null,
            text: null,
            lights: null,
            body: null,
            shadow: null,
          },
        },
        {
          name: "閱讀空間 bibliotheca",
          to: "/bibliotheca",
          loaded: false,
          complete: false,
          tl: {
            tl: null,
            tlMouse: null,
          },
          elem: {
            item: null,
            text: null,
            lights: null,
            body: null,
            shadow: null,
          },
        },
        {
          name: "圖錄",
          to: "/bibliotheca",
          loaded: false,
          complete: false,
          tl: {
            tl: null,
            tlMouse: null,
          },
          elem: {
            item: null,
            text: null,
            lights: null,
            body: null,
            shadow: null,
          },
        },
      ],
    };
  },
  components: {
    DialogAboutMuseumContent,
  },
  mounted() {
    // if(this.engine) this.engine.remove();
    document.querySelector("#starCanvas")?.remove();
  },
  methods: {
    svgLoaded(elem, i) {
      const vm = this;
      this.menuItems[i].loaded = true;
      this.menuItems[i].elem.item = document.querySelector(`#menu-item-${i}`);
      this.menuItems[i].elem.text = document.querySelector(
        `#menu-item-${i} svg #text`
      );
      this.menuItems[i].elem.lights = document.querySelector(
        `#menu-item-${i} svg #lights`
      );
      this.menuItems[i].elem.body = document.querySelector(
        `#menu-item-${i} svg #body`
      );
      this.menuItems[i].elem.shadow = document.querySelector(
        `#menu-item-${i} .shadow`
      );
      const item = this.menuItems[i].elem.item;
      const text = this.menuItems[i].elem.text;
      const lights = this.menuItems[i].elem.lights;
      const body = this.menuItems[i].elem.body;
      const shadow = this.menuItems[i].elem.shadow;

      this.tl.main = new gsap.timeline();

      this.tl.main.set("#menu-item-light", {
        opacity: 0,
        y: "-=4vh",
        display: "none",
      });

      this.tl.main.set(item, {
        opacity: 0,
        y: "-=25vh",
        display: "none",
      });
      this.tl.main.set(shadow, {
        opacity: 0.08,
        y: "+=800",
      });
      this.tl.main.set([text, lights, body], {
        opacity: 0,
      });
      if (i == 0) {
        this.tl.main.set(lights, {
          x: -50,
          y: -50,
        });
      }

      this.tl.main.to(
        [item, body],
        {
          delay: i * 0.45,
          opacity: 1,
          duration: 5,
          y: 0,
          display: "block",
          ease: Power4.easeOut,
        },
        1
      );
      this.tl.main.to(
        "#menu-item-light",
        {
          duration: 4,
          opacity: 1,
          y: 0,
          display: "block",
          ease: Power2.easeOut,
        },
        0
      );

      gsap.set(body, {
        x: this.randomX(-0.5),
        y: this.randomX(0.5),
      });
      gsap.set(lights, {
        x: this.randomX(-0.5),
        y: this.randomX(0.5),
      });

      this.moveX(body, 0.5);
      this.moveY(body, -0.5);

      if (i != 0) {
        this.moveX(lights, 0.5);
        this.moveY(lights, -0.5);
      }
      this.tl.main.to(
        shadow,
        {
          opacity: 0.08,
          duration: 5,
          y: 0,
          display: "block",
          ease: Power4.easeOut,
          onComplete: function() {
            vm.menuItems[i].complete = true;
          },
        },
        0
      );
    },
    menuOver(i) {
      // if (!this.menuItems[i].loaded || !this.menuItems[i].complete) return;

      const tl = new gsap.timeline();
      // const item = this.menuItems[i].elem.item;
      const text = this.menuItems[i].elem.text;
      const lights = this.menuItems[i].elem.lights;
      // const body = this.menuItems[i].elem.body;
      const shadow = this.menuItems[i].elem.shadow;

      tl.to(text, { duration: 1, opacity: 1, ease: Power4.easeOut }, 0);
      tl.to(lights, { duration: 1, opacity: 1, ease: Power4.easeOut }, 0);
      tl.to(shadow, { duration: 1, opacity: 0.4, ease: Power4.easeOut }, 0);
    },
    menuOut(i) {
      // if (!this.menuItems[i].loaded || !this.menuItems[i].complete) return;
      const tl = new gsap.timeline();
      const text = this.menuItems[i].elem.text;
      const lights = this.menuItems[i].elem.lights;
      const shadow = this.menuItems[i].elem.shadow;

      tl.to(text, { duration: 1, opacity: 0, ease: Power4.easeOut }, 0);
      tl.to(lights, { duration: 1, opacity: 0, ease: Power4.easeOut }, 0);
      tl.to(shadow, { duration: 1, opacity: 0.08, ease: Power4.easeOut }, 0);
    },
    moveX(target, direction) {
      var vm = this;
      gsap.to(target, {
        duration: vm.randomTime(),
        x: vm.randomX(direction),
        ease: Sine.easeInOut,
        onComplete: vm.moveX,
        onCompleteParams: [target, direction * -1],
      });
    },
    moveY(target, direction) {
      var vm = this;
      gsap.to(target, {
        duration: vm.randomTime(),
        y: vm.randomY(direction),
        ease: Sine.easeInOut,
        onComplete: vm.moveY,
        onCompleteParams: [target, direction * -1],
      });
    },
    random(min, max) {
      const delta = max - min;
      return (direction = 1) => (min + delta * Math.random()) * direction;
    },
    clickOnItem(i) {
      if (!this.clickable) return;
      if (i === 3) {
        if (!this.visitedAll) return;

        this.$gtag.event("Download Catalogue", {});
        // window.location.href = "./exhibition_catalogue_20211016.pdf" ;
        window.open("./exhibition_catalogue_20210831.pdf");
        return;
      }
      this.clickable = false;
      var vm = this;

      this.tl.main.to(
        ["#menu-item-light", ".menu-item"],
        {
          opacity: 0,
          duration: 3,
          ease: Power2.easeIn,
          transform: "translateY(-500px)",
          display: "none",
          stagger: 0.5,
          onComplete: function() {
            if (i == 1) {
              vm.$router.push({ name: `instruments` });
            } else if (i == 2) {
              vm.$router.push({ name: `Scenes`, params: { id: 1 } });
            } else if (i == 0) {
              vm.$router.push({ name: "Gallery1-Home" });
            } else {
              vm.$router.push({ name: `Gallery${i + 1}` });
            }
          },
        },
      );
      // this.tl.main.fromTo(
      //   ".menu-container-cover",
      //   { opacity: 0 },
      //   {
      //     opacity: 1,
      //     duration: 3,
      //     ease: Power2.easeIn,
      //     display: "block",
      //   },
      //   "0"
      // );
    },
    openAboutMuseum() {
      this.dialog.museum = true;
    },
  },
  computed: {
    visitedAll() {
      return this.$store.state.visited[0] == true &&
        this.$store.state.visited[1] == true &&
        this.$store.state.visited[2] == true
        ? true
        : false;
    },
    randomX() {
      return this.random(10, 20);
    },
    randomY() {
      return this.random(20, 30);
    },
    randomDelay() {
      return this.random(0, 1);
    },
    randomTime() {
      return this.random(3, 5);
    },
    randomTime2() {
      return this.random(5, 10);
    },
  },
};
</script>

<style lang="scss">
#menu-item-3 .main {
  position: relative;
}

#menu-item-light {
  opacity: 0;
  position: absolute;
  left: 5vw;
  top: 12vh;
  cursor: pointer;
}
.light-container {
  width: 100px;
  height: 100px;
  position: relative;
  .light-dot,
  .light {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .light {
    animation: breathing 4s ease-in-out infinite;
  }

  .light-dot {
    width: 10px;
    height: 10px;
    background-color: $color--brown;
    border-radius: 50%;
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.menu-container-cover {
  opacity: 0;
  display: none;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  background: $color--bg;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999999;
}
.menu-item-tooltips {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: auto;
  // background: rgba(0, 0, 0, 0.3);
  background: transparent;
  padding: 0.5rem 0.1rem;
  border-radius: 15px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
#menu-item-3.disabled .menu-item-svg {
  opacity: 0.3 !important;
}
#menu-item-3.disabled:hover .menu-item-tooltips {
  opacity: 1;
}
#menu-item-3.disabled {
  cursor: unset !important;
}
.disabled {
  // cursor: not-allowed !important;
}
.menu-item svg {
  overflow: visible;
  pointer-events: none;
}
.menu-container-center {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 100vw;
  // height: 100vh;
}
.menu-container {
  // inset: 0;
  // margin: auto;
  // display: grid;
  // grid-gap: 0;
  // grid-template:
  //   "b b a" auto
  //   "b b c" 20ch
  //   "b b c" 2rem/45% 0px 1fr;
}
.menu-item {
  cursor: pointer;
  margin: 0 auto;
  position: absolute;
}

#menu-item-0 {
  max-width: 28vw;
  top: 20vh;
  left: 17vw;
  grid-area: b;
  .main {
    transform: translateX(100px) !important;
    svg #lights {
      position: absolute;
      left: -80px !important;
      top: -50px !important;
      // transform: translate(-80px, -50px) !important;
    }
  }
  // max-width: 450px;
}

#menu-item-1 {
  grid-area: c;
  max-width: 220px;
  // max-width: 20vw;
  z-index: 99;
  top: 45vh;
  left: 54vw;
}

#menu-item-2 {
  grid-area: a;
  max-width: 220px;
  // max-width: 21vw;
  top: 20vh;
  left: 52vw;
}

#menu-item-3 {
  grid-area: c;
  max-width: 180px;
  // max-width: 20vw;
  top: 28vh;
  left: 74vw;
}
.shadow {
  opacity: 0.08;
}
</style>
